import React from "react";
import get from "lodash/get";

// Components
import Title from "../../components/Title";
import Quote from "../../components/Quote";
import Project from "../../components/Project";
import Info from "../../components/Info";
import Link from "../../components/UI/Link";

// Assets
import {
    InfoWrapper,
    MainTitleWrapper,
    LinkWrapper,
    ProjectLink,
    EmptyProjectLink,
} from "./styled";

// Create Structure
const Structure = ({ contentRef, structure }) => {
    let projectIndex = 0;
    let totalProject = structure.filter((el) => el.type === 2).length;

    let page = structure.map((item, index) => {
        switch(item.type) {
            // Type Quote
            case 1:
                return <Quote key={`quote-${index}`} ref={contentRef} {...item.value} />
            // Type Project
            case 2:
                const { client, link } = item.value;
                projectIndex++
                return (
                    <React.Fragment key={`${client}-wrapper`}>
                        {(link && <ProjectLink key={client} hoverStyle="none" to={link}>
                            <Project
                                {...item.value}
                                even={projectIndex % 2 === 1}
                            />
                        </ProjectLink>) || <EmptyProjectLink><Project
                            {...item.value}
                            even={projectIndex % 2 === 1}
                        /></EmptyProjectLink>}
                        {projectIndex === totalProject &&
                            <LinkWrapper>
                                <Link key={`all-projects`} hoverStyle="external" to={`/work`} weight="bold">
                                    Check all projects
                                </Link>
                            </LinkWrapper>
                        }
                    </React.Fragment>
                )
            // Type Main Title
            case 3:
                const { mainTitle } = item.value;
                return (
                    <InfoWrapper key={`info-wrapper-${index}`} style={{ marginBottom: "40px" }}>
                        <Title
                            key={`structure-main-title-${index}`}
                            title={mainTitle}
                            element={MainTitleWrapper}
                            inline={true}
                            justify={`center`}
                        />
                    </InfoWrapper>
                )
            // Type Info
            case 4:
                const { title, subtitle, headlineText, text, width, image, order } = item.value;
                let maxWidth = image ? get(image, 'src.childImageSharp.gatsbyImageData.width', undefined) : undefined;
                let _start = (item.value.link && item.value.link.label !== undefined) || 0;

                return (
                    <InfoWrapper key={`info-wrapper-${index}`} start={_start ? 1 : 0}>
                        <Info
                            key={`info-${index}`}
                            title={title && { label: title.label, color: title.color }}
                            subtitle={subtitle && { label: subtitle.label, color: text.color, weight: "medium" }}
                            headlineText={headlineText && { label: headlineText.label, color: headlineText.color, weight: headlineText.weight }}
                            text={text && { label: text.label, weight: "regular", color: subtitle.color }}
                            link={item.value.link && item.value.link.label && { label: item.value.link.label, weight: "bold", color: item.value.link.color, link: item.value.link.link }}
                            width={width ? (parseFloat(width)/12) * 100 : 100}
                            order={order === 'right' ? 1 : 0}
                            image={image ? image : null}
                            imageMaxWidth={maxWidth ? `${maxWidth/2}px` : null}
                            start={_start ? 1 : 0}
                        />
                    </InfoWrapper>
                )
            // Type Title Info
            case 5:
                return <></>
            default:
                return <></>
        }
    })

    return page;
}

export default Structure