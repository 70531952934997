import styled from "@emotion/styled";
import { Layout, media } from "@theme";

export const SectionContainer = styled(Layout.Wrapper)`
    display: flex;
    margin: auto;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: center;
    scroll-behavior: smooth;

    ${media.smallMedium} {
        justify-content: flex-start;
    }
    ${media.small} {
        justify-content: flex-start;
    }
`;

export const DefaultBackground = styled.div``;