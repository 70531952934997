import React from "react";
import PropTypes from "prop-types";
import { MainTitle, Container, Content, ColouredText } from "./styled";


// 

// Default Wrapper
const defaultWrapper = ({ children }) => <MainTitle weight="extra-bold">{children}</MainTitle>;

// Main title component
const Title = React.forwardRef(({ title, element, justify, inline = false }, forwardRef) => {

    // Wrapper
    const Wrapper = element ? element : defaultWrapper;

    const Title = () => <React.Fragment>
        {title &&
            title.map((paragraph, index) => (
                <React.Fragment key={`title-${index}`}>
                    <ColouredText
                        key={`title-p-${index}`}
                        inline={inline}
                        styling={paragraph.styled}>
                        {paragraph.label}
                    </ColouredText>
                    {!inline && <br />}
                </React.Fragment>
            ))}
    </React.Fragment>

    return (
        <Container ref={forwardRef} justify={justify}>
            <Content>
                <Wrapper>
                    <Title />
                </Wrapper>
            </Content>
        </Container>
    )
})

Title.propTypes = {
    title: PropTypes.array,
}

export default Title;
