import React from "react";
import styled from "@emotion/styled";
import { Layout, Colors, media } from "@theme";
import Link from "../../components/UI/Link";
import Image from "../../components/UI/Image";
import { H2, H3, H4 } from "../../components/UI/Typography";

export const Wrapper = styled.div`
    position: relative;

    & #title {
        margin-top: 100px;
    }

    ${media.smallMedium} {
        margin-top: 0px;
        padding-top: 30px;
        
        & #projects {
            margin-top: 0px;
        }

        & #title {
            margin-top: 50px;
        }
    }

    ${media.tablet} {
        & #title {
            margin-top: 0px;
        }
        
        @media (orientation: landscape) {
            & #title h1 {
                font-size: 2em;
            }
        }
    }

    ${media.small} {
        & #projects {
            margin-top: 0px;
        }
    }
`;

export const Illustration = styled(Image)`
    position: absolute !important;
    top: -550px;
    right: 140px;
    width: 400px;

    ${media.largest} {
        top: -425px;
        width: 325px;
    }

    ${media.large} {
        top: -515px;
    }

    ${media.smallMedium} {
        top: -370px;
        width: 325px;
    }

    ${media.tablet} {
        right: 60px;
        top: -300px;
        width: 250px;
    }

    ${media.small} {
        width: 325px;
        top: -370px;

        @media (orientation: landscape) {
            margin-top: -35px;
        }
    }
    ${media.mobileSM} {
        width: 275px;
        top: -335px;
        left: 0;
        right: 0;
        margin: auto;

        @media (orientation: landscape) {
            margin-top: -35px;
        }
    }
`;

export const Background = styled.div`
    position: relative;
    padding-top: 615px;
    padding-bottom: 100px;
    margin-top: -350px;

    background-image: url("${props => props.largeBackgroundImage.svg.dataURI}");
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: auto;

    ${media.largest} {
        margin-top: -550px;
        background-size: 1920px;
        padding-top: 715px;
        padding-bottom: 0px;
    }

    ${media.large} {
        background-image: url("${props => props.backgroundImage.svg.dataURI}");
        padding-top: 650px;
        margin-top: -400px;
        background-size: auto;
    }

    ${media.smallMedium} {
        margin-top: -500px;
        background-size: 1150px;
    }

    ${media.tablet} {
        margin-top: -615px;
        background-size: 950px;

        @media (orientation: landscape) {
            margin-top: -600px;
        }
    }

    ${media.small} {
        margin-top: -275px;
        background-size: auto;
        
        @media (orientation: landscape) {
            margin-top: -400px;
        }
    }
`;

export const TitleWrapper = styled.div``;

export const TitleExtra = styled(H4)`
    margin-top: 30px;
    color: black;
`;

export const LinkWrapper = styled.div`
    margin-top: 3em;
    margin-bottom: 4em;
    text-align: center;
    text-transform: uppercase;
`;

export const ProjectLink = styled(Link)`
    padding: 60px 0;

    ${media.tablet} {
        padding: 0;
    }
`;

export const EmptyProjectLink = styled.div`
    padding: 60px 0;

    ${media.tablet} {
        padding: 0;
        & > div {
            height: auto;
        }
    }
`;

export const InfoWrapper = styled.div`
    padding: 20px 30px;
    margin-top: 40px;

    ${media.tablet} {
        padding: 0;
        padding-bottom: 10px;
        margin: ${props => props.start ? '40px auto' : '70px auto'};
    }
    ${media.small} {
        padding: 0;
        margin: ${props => props.start ? '20px auto' : '15px auto'};
    }
`;

export const BGInfoWrapper = styled(Layout.Wrapper)`
    position: relative;
    padding-bottom: 10px;
`;

export const MainTitleWrapper = ({ children }) => <H2 weight="bold" style={{ letterSpacing: "-1.03px", textAlign: "center" }}>{children}</H2>;

export const QuotesWrapper = styled.div`
    padding-top: 80px;
    background-color: ${Colors.testimonials};
`;

export const QuotesWrapperTitle = styled(H3)`
    text-align: center;

    ${media.small} {
        max-width: 250px;
        margin: 0 auto;
    }
`;