import styled from "@emotion/styled";
import { media, Colors } from "@theme";
import { H2 } from "../UI/Typography";

export const Container = styled.div`
    display: flex;
    justify-content: ${props => props.justify ? props.justify : 'left'};
    align-items: center;
`;

export const Content = styled.div``

export const ColouredText = styled.span`
    display: inline;
    background-color: ${props => (props.styling && props.styling.backgroundColor) || 'transparent'};
    padding: ${props => (props.styling && props.styling.padding) || '5px 10px'};
    color: ${props => (props.styling && props.styling.color) || Colors.title};
    margin: ${props => (props.styling && props.styling.margin) || '0px'};
    border-radius: 3px;
    
    ${media.small} {
        display: ${props => props.inline ? 'inline-block' : 'inline'};
    }
`;

export const MainTitle = styled(H2)`
    ${media.minLargest} {
        font-size: 2.75em;
    }
`;