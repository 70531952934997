import React from 'react';
import PropTypes from 'prop-types';
import { Section } from "react-scroll-section";

// Assets
import { DefaultBackground, SectionContainer } from "./styled";

const Container = ({ id, children, Background = DefaultBackground, style = {} }) =>
  <Section key={id} id={id} style={{ position: 'relative' }}>
    <Background />
    <SectionContainer style={style}>{children}</SectionContainer>
  </Section>

Container.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Container
