import React from "react";
import get from "lodash/get";

// Components
import Section from "../../components/UI/Section";
import Title from "../../components/Title";
//import MouseIcon from "../../components/Home/Mouse";
import Quote from "../../components/Quote";
import CTA from "../../components/CTA";
import Structure from "./structure";
import Info from "../../components/Info";

// Assets
import { ScrollingProvider, /*SectionLink*/ } from "react-scroll-section";
import {
    Wrapper,
    Background,
    InfoWrapper,
    TitleWrapper,
    TitleExtra,
    QuotesWrapper,
    QuotesWrapperTitle,
    BGInfoWrapper,
    Illustration,
} from "./styled";

const MainBG = ({ 
    bgFrame,
    illustrationImage,
    backgroundImage,
    largeBackgroundImage
}) => {

    // Main BG
    const { title, subtitle, text, headlineText, width, image, order } = bgFrame.value;
    let maxWidth = image ? get(image, 'src.childImageSharp.gatsbyImageData.width', undefined) : undefined;
    let _start = (bgFrame.value.link && bgFrame.value.link.label !== undefined) || 0;
    return (
        <div style={{position: "relative"}}>
            <Background 
                backgroundImage={backgroundImage}
                largeBackgroundImage={largeBackgroundImage}
            >
                <BGInfoWrapper>
                    <Illustration image={illustrationImage.src} alt={illustrationImage.label} />
                    <InfoWrapper key={`info-wrapper-main`} start={_start ? 1 : 0}>
                        <Info
                            key={`info-main`}
                            title={title && { label: title.label, color: title.color }}
                            subtitle={subtitle && { label: subtitle.label, color: subtitle.color }}
                            headlineText={headlineText && { label: headlineText.label, color: headlineText.color, weight: headlineText.weight }}
                            text={text && { label: text.label, weight: "regular", color: text.color }}
                            width={width ? (parseFloat(width)/12) * 100 : 100}
                            order={order === 'right' ? 1 : 0}
                            image={image ? image : null}
                            imageMaxWidth={maxWidth ? `${maxWidth/2}px` : null}
                            start={_start ? 1 : 0}
                        />
                    </InfoWrapper>
                </BGInfoWrapper>
            </Background>
        </div>
    )
}

// Home Container
const Home = ({ contentRef, dataTitle, dataHero, structure, dataQuotes, dataCTA }) => {
    let bgFrame = structure.shift();
    return (
        <Wrapper>
            <ScrollingProvider scrollBehavior="smooth">
                <Section key={'sectionTitle'} id={'title'}>
                    <TitleWrapper>
                        <Title
                            key={'title'}
                            title={dataTitle}
                            ref={contentRef}
                        />
                        <TitleExtra weight={"regular"}>* not just take orders.</TitleExtra>
                    </TitleWrapper>
                    {/* <SectionLink section={'projects'}>
                        {link => <MouseIcon onClick={link.onClick} selected={link.isSelected} />}
                    </SectionLink> */}
                </Section>
                <MainBG 
                    bgFrame={bgFrame} 
                    illustrationImage={dataHero.illustrationImage}
                    backgroundImage={dataHero.backgroundImage}
                    largeBackgroundImage={dataHero.largeBackgroundImage}
                />
                <Section key={'sectionProjects'} id={'projects'}>
                    <Structure
                        key={"structure"}
                        contentRef={contentRef}
                        structure={structure}
                    />
                </Section>
                {dataQuotes && <QuotesWrapper>
                    <QuotesWrapperTitle>{dataQuotes.title}</QuotesWrapperTitle>
                    {dataQuotes.quotes && dataQuotes.quotes.map((item, index) => <Quote key={`testimonials-quote-${index}`} {...item.value} />)}
                </QuotesWrapper>}
                {dataCTA && <CTA {...dataCTA} />}
            </ScrollingProvider>
        </Wrapper>
    )
}

export default Home
