import React, { useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import get from "lodash/get"
import Home from "../containers/home"

// Create structure for Homepage
const structure = data => {
    let finalStructure = []

    // Get Structure Info
    const structureInfo = get(data, "homeYaml.structure", [])
    // Get Quotes
    const allQuotes = get(data, "quotes.nodes", undefined)
    // Get Projects
    const allProjects = get(data, "projects.nodes", undefined)

    structureInfo.forEach(item => {
        if (item.quote) {
            let quotes = allQuotes.filter(quote => quote.name === item.quote)
            if (quotes.length > 0) {
                finalStructure.push({
                    type: 1,
                    value: get(quotes, "[0].childQuotesYaml", []),
                })
            }
        } else if (item.project) {
            let projects = allProjects.filter(
                project => project.name === item.project
            )
            if (projects.length > 0) {
                finalStructure.push({
                    type: 2,
                    value: get(projects, "[0].childProjectsYaml", []),
                })
            }
        } else if (item.mainTitle) {
            finalStructure.push({
                type: 3,
                value: item,
            })
        } else if (item.title || item.subtitle) {
            finalStructure.push({
                type: 4,
                value: item,
            })
        }
    })

    return finalStructure
}

// Create testimonials for Homepage
const testimonials = data => {
    let finalStructure = []

    // Get Quotes Title
    const quotesTitle = get(data, "homeYaml.testimonials.title", undefined)
    // Get Quotes Info
    const quotesInfo = get(data, "homeYaml.testimonials.quotes", [])
    // Get Quotes
    const allQuotes = get(data, "quotes.nodes", undefined)

    quotesInfo.forEach(item => {
        let quotes = allQuotes.filter(quote => quote.name === item)
        if (quotes.length > 0) {
            finalStructure.push({
                value: get(quotes, "[0].childQuotesYaml", []),
            })
        }
    })

    return {
        title: quotesTitle,
        quotes: finalStructure,
    }
}

const IndexPage = ({ data }) => {
    const contentRef = useRef()
    const metadata = data.site.siteMetadata

    // Get SEO
    const dataSEO = get(data, "homeYaml.seo", undefined)
    // Get Title
    const dataTitle = get(data, "homeYaml.title", undefined)
    // Get Hero
    const dataHero = get(data, "homeYaml.hero", undefined)
    // Get CTA
    const dataCTA = get(data, "homeYaml.CTA", undefined)

    // Get Testimonials
    const dataQuotes = testimonials(data)

    // Get Homepage Structure
    const pageStructure = structure(data)

    return (
        <Layout ref={contentRef}>
            <SEO
                title={dataSEO.title || metadata.name}
                description={dataSEO.description || metadata.description}
            />
            <Home
                contentRef={contentRef}
                dataTitle={dataTitle}
                dataHero={dataHero}
                structure={pageStructure}
                dataQuotes={dataQuotes}
                dataCTA={dataCTA}
            />
        </Layout>
    )
}

export default IndexPage

export const query = graphql`
    query HomeQuery {
        homeYaml {
            seo {
                title
                description
            }
            title {
                label
                styled {
                    backgroundColor
                    color
                    padding
                }
            }
            hero {
                illustrationImage {
                    src {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                    label
                }
                backgroundImage {
                    svg {
                        dataURI
                    }
                }
                largeBackgroundImage {
                    svg {
                        dataURI
                    }
                }
                subtitle {
                    label
                    color
                }
            }
            CTA {
                title {
                    label
                    color
                }
                subtitle {
                    label
                    color
                }
                grid {
                    title {
                        label
                        color
                    }
                    image {
                        src {
                            childImageSharp {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                        label
                    }
                }
                text {
                    label
                    color
                }
                actions {
                    label
                    link
                    bgColor
                    textColor
                }
                innerBackground
                bgColor
            }
            structure {
                project
                title {
                    label
                    color
                }
                subtitle {
                    label
                    color
                }
                headlineText {
                    label
                    color
                    weight
                }
                text {
                    label
                    color
                }
                link {
                    label
                    link
                    color
                }
                image {
                    src {
                        childImageSharp {
                            gatsbyImageData(width: 960, placeholder: NONE)
                        }
                    }
                    label
                }
                order
                width
                mainTitle {
                    label
                    styled {
                        backgroundColor
                        color
                        padding
                    }
                }
            }
            testimonials {
                title
                quotes
            }
        }

        site {
            siteMetadata {
                title
                name
                description
            }
        }

        projects: allFile(filter: { childProjectsYaml: { id: { ne: null } } }) {
            nodes {
                id
                name
                childProjectsYaml {
                    client
                    background_color
                    text_color
                    dark_mode
                    label
                    link
                    services
                    text
                    title
                    screen1 {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                    screen2 {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                    frame
                }
            }
        }

        quotes: allFile(filter: { childQuotesYaml: { id: { ne: null } } }) {
            nodes {
                id
                name
                childQuotesYaml {
                    text
                    author {
                        name
                        ocupation
                        photo {
                            childImageSharp {
                                gatsbyImageData(width: 132, placeholder: NONE)
                            }
                        }
                    }
                }
            }
        }
    }
`
